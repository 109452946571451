var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"e9765c5f418b06b06a0aad45bbb617a73561c481"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.VERCEL_ENV !== "development") {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      "https://d060e7cb5a6941129df234504b0be100@o508102.ingest.sentry.io/5608670",
    // Adjust this value in production, or use tracesSampler for greater control
    integrations: [
      Sentry.captureConsoleIntegration({
        levels: ["error"],
      }),
    ],
    tracesSampleRate: 0.5,
    sampleRate: 0.5,
    release: process.env.VERCEL_GIT_COMMIT_SHA,
    environment: process.env.VERCEL_ENV,
    initialScope: {
      extra: {
        VERCEL_URL: process.env.VERCEL_URL,
        VERCEL_ENV: process.env.VERCEL_ENV,
      },
    },
    beforeSend(event, hint) {
      const error = hint.originalException;
      // Ignore UnhandledRejection: Non-Error promise rejection captured with value: Timeout coming from recaptcha
      // https://github.com/getsentry/sentry-javascript/issues/2514
      if (error === "Timeout") return null;

      if (
        error &&
        error.message &&
        (error.message.includes("There was an error while hydrating") ||
          error.message.includes("Hydration failed") ||
          error.message.includes(
            "Text content does not match server-rendered HTML"
          ) ||
          error.message.includes("Minified React error #418") ||
          error.message.includes("Minified React error #423") ||
          error.message.includes("Minified React error #425") ||
          error.message.includes("Loading initial props cancelled"))
      ) {
        return null;
      }

      return event;
    },
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
